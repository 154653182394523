import Color from '@gamepark/oriflamme/Color'
import Game from '@gamepark/oriflamme/Game'
import OriflammeRules from '@gamepark/oriflamme/OriflammeRules'
import { useGame, usePlayerId } from '@gamepark/react-client'
import { useMemo } from 'react'
import { getPossiblePlaces } from '@gamepark/oriflamme/Line'
import Phase from '@gamepark/oriflamme/Phase'

export default function usePossiblePlaces() {
  const game = useGame<Game>()
  const playerId = usePlayerId<Color>()

  return useMemo(
    () =>
      game &&
      playerId &&
      game.phase == Phase.Placement &&
      game.currentPlayer == playerId
        ? getPossiblePlaces(playerId, game.cards)
        : [],
    [game, playerId],
  )
}
