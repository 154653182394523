import CardType, { cardTypes } from './CardType'
import Color from './Color'
import shuffle from 'lodash.shuffle'
import { arrangeCardsInHand } from './Line'

export enum LocationType {
  hand = 1,
  line,
  discard,
}

export type CardLocation = HandLocation | LineLocation | DiscardLocation

export function isCardInLine(card: Card): card is CardInLine {
  return isLineLocation(card.location)
}

export function isLineLocation(
  location: CardLocation,
): location is LineLocation {
  return location.type === LocationType.line
}

export function isHandLocation(
  location: CardLocation,
): location is HandLocation {
  return location.type === LocationType.hand
}

export function isDiscardLocation(
  location: CardLocation,
): location is LineLocation {
  return location.type === LocationType.discard
}

export type HandLocation = {
  type: LocationType
  x: number
}

export type LineLocation = {
  type: LocationType
  x: number
  z: number
}

export type DiscardLocation = {
  type: LocationType
  x: number
}

type Card = {
  color: Color
  type?: CardType
  location: CardLocation
  flipped?: boolean //flipped = true means card is not visible
  points?: number
  choosenType?: CardType
}

export type CardInLine = Card & {
  location: LineLocation
}

export default Card

/**
 * createCards returns an array with 7 cards initialized (a set for each player)
 */
export function createCards(colors: Color[]): Card[] {
  let result: Card[] = []
  colors.forEach((color) => {
    let playerHand: Card[] = []
    for (let index = 1; index <= cardTypes.length; index++) {
      playerHand.push({
        color: color,
        type: index,
        location: { type: LocationType.hand, x: index },
      })
    }
    playerHand = shuffle(playerHand)
    playerHand.splice(0, 3)
    // playerHand.splice(0, 2)// hack for testing purpose
    arrangeCardsInHand(playerHand)
    result = result.concat(playerHand)
  })
  return result
}
