import Color from '@gamepark/oriflamme/Color'
import Game from '@gamepark/oriflamme/Game'
import OriflammeRules from '@gamepark/oriflamme/OriflammeRules'
import { useGame, usePlayerId } from '@gamepark/react-client'
import { useMemo } from 'react'

export default function useLegalMoves() {
  const game = useGame<Game>()
  const playerId = usePlayerId<Color>()

  const legalMoves = useMemo(
    () =>
      game && playerId ? new OriflammeRules(game).getLegalMoves(playerId) : [],
    [game, playerId],
  )

  return legalMoves
}
