import { Rules, SecretInformation } from '@gamepark/rules-api'
import CardType from '../../CardType'
import Color from '../../Color'
import Game from '../../Game'
import {
  getCardVisibleOnPointer,
  getPossiblePlaces,
  getVisibleLine,
} from '../../Line'
import Move, { MoveType } from '../../moves/Move'
import OriflammeCardsRules from '../OriflammeCardsRules'

/**
 * This class implements the rules of the board game.
 * It must follow Game Park "Rules" API so that the Game Park server can enforce the rules.
 */
export default class HeirRules extends OriflammeCardsRules {
  /**
   * Here you can return the moves that should be automatically played when the game is in a specific state.
   * Here is an example from monopoly: you roll a dice, then move you pawn accordingly.
   * A first solution would be to do both state updates at once, in a "complex move" (RollDiceAndMovePawn).
   * However, this first solution won't allow you to animate step by step what happened: the roll, then the pawn movement.
   * "getAutomaticMoves" is the solution to trigger multiple moves in a single action, and still allow for step by step animations.
   * => in that case, "RollDice" could set "pawnMovement = x" somewhere in the game state. Then getAutomaticMove will return "MovePawn" when
   * "pawnMovement" is defined in the state.
   * Of course, you must return nothing once all the consequences triggered by a decision are completed.
   * VERY IMPORTANT: you should never change the game state in here. Indeed, getAutomaticMove will never be called in replays, for example.
   *
   * @return The next automatic consequence that should be played in current game state.
   */
  getAutomaticMoves(): Move[] {
    console.log('getAutomaticMoves')
    const activeCard = getCardVisibleOnPointer(this.state.cards, this.state.pointer)
    if (!activeCard || !activeCard.type || activeCard.flipped) return super.getAutomaticMoves()

    let result: Move[] = []

    const visibleCardsWithSameType = getVisibleLine(this.state.cards).filter(
      (card) => card.type == activeCard.type && !card.flipped,
    ).length
    console.log ("nombre :", visibleCardsWithSameType)
    if (visibleCardsWithSameType == 1)
      result.push({
        type: MoveType.getTokens,
        nb: 2,
        to: activeCard.color,
      })
    result.push({ type: MoveType.movePointer})
    return result
  }
}
