import { css, keyframes } from '@emotion/react'
import Color from '@gamepark/oriflamme/Color'

export const screenRatio = 16 / 9

export const cardRatio = 462 / 252
export const lanceRatio = 75 / 300
export const cardWidth = 20
export const cardHeight = cardWidth * cardRatio
export const coinSize = 10
export const cardInlineRatio = 0.6
export const cardInLineWidth = cardWidth * cardInlineRatio
export const cardInLineHeight = cardHeight * cardInlineRatio
export const maxMarginBetweenCards = 5

export const colors: Record<Color, string> = {
  [Color.Blue]: '#4e5778',
  [Color.Red]: '#9a4143',
  [Color.Green]: '#395941',
  [Color.Yellow]: '#755e2a',
  [Color.Grey]: '#605d58',
}

export const ITEM = 'Card' //draggable ITEM
export const opponentSize = 0.2
export const hOverlap = 10 / 100

export const vOverlap = hOverlap*cardRatio

export const slideKeyframes = keyframes`
  0%, 80% {
    transform: translate(-33%, 33%);
    opacity: 0;
  }
  90% {
    opacity: 0.8;
  }
  100% {
    transform: translate(33%, -33%);
    opacity: 0;
  }
`

export const shineEffect = css`
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    top: -100%;
    left: -100%;
    width: 300%;
    height: 300%;
    animation: ${slideKeyframes} 2s infinite;
    z-index: 1;
    background: linear-gradient(to top right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(128, 186, 232, 0) 99%, rgba(125, 185, 232, 0) 100%);
    border-radius: 1.5em;
  }
`

// for displaying playerPanel
export const fromLeft = true
export const fromTop = true
export const row = true
export const XOffset = 0 //em from rigth or left (depending on fromRight value)
export const YOffset = 7 //em from top or bottom (depending on fromTop value)
export const marginX = 1
export const marginY = 1
export const playerPanelHeight = 17.7
export const playerPanelWidth = 19

//then auto calculate (always from top and left)
export const playerPanelX = (position: number) => {
  let offset =
    (fromLeft ? XOffset : XOffset + playerPanelWidth) +
    (row ? position * (playerPanelWidth + marginX) : 0)
  return fromLeft ? offset * screenRatio : (100 - offset) * screenRatio
}
export const playerPanelY = (position: number) => {
  let offset =
    (fromTop ? YOffset : YOffset + playerPanelHeight) +
    (row ? 0 : position * (playerPanelHeight + marginY))
  return fromTop ? offset : 100 - offset
}
