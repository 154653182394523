/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import Card, { LineLocation } from '@gamepark/oriflamme/Card'
import { MoveType } from '@gamepark/oriflamme/moves/Move'
import { HTMLAttributes } from 'react'
import { useDrop } from 'react-dnd'
import { cardHeight, cardWidth, ITEM } from './styles'

type Props = {
  location: LineLocation
} & HTMLAttributes<HTMLDivElement>

export default function CardDropArea({ location, ...props }: Props) {
  const [{ dragging, over }, ref] = useDrop({
    accept: 'Card', //TODO use constant
    collect: (monitor) => ({
      dragging: monitor.getItemType() === ITEM,
      over: monitor.isOver(),
    }),
    drop: (item: Card) => ({
      type: MoveType.placeCard,
      cardColor: item.color,
      cardType: item.type,
      location: location,
    }),
  })

  return <div css={[areaCss, over && overCss]} ref={ref} {...props} />
}

const hoverStyle = css`
  transform: scale(1.2);
`

const areaCss = css`
  width: ${cardWidth}em;
  height: ${cardHeight}em;
  background-color: rgba(255, 255, 255, 0.5);

  border-radius: 1.5em;
  box-shadow: 0 0 1em black, 0 0 1em black;

  transform-style: preserve-3d;
  transform: translateZ(0);
  -webkit-font-smoothing: subpixel-antialiased;
  transition: transform 1s ease-in-out;
`

const overCss = css`
background-color: rgba(100, 255, 100, 0.5);
`
