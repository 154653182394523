/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import Card from '@gamepark/oriflamme/Card'
import Color from '@gamepark/oriflamme/Color'
import Game from '@gamepark/oriflamme/Game'
import Player from '@gamepark/oriflamme/Player'
// import { getPlayerName } from '@gamepark/brigands/BrigandsOptions'
// import PlayerColor from '@gamepark/brigands/players/PlayerColor'
// import PlayerView from '@gamepark/brigands/players/PlayerView'
import {
  Avatar,
  GamePoints,
  PlayerTimer,
  useGame,
  usePlayer,
} from '@gamepark/react-client'
import { SpeechBubbleDirection } from '@gamepark/react-client/dist/Avatar'
import { HTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'
import Coin from './Coin'
import FirstPlayerToken from './FirstPlayerToken'
import MoneyCounter from './MoneyCounter'
import {
  colors,
  playerPanelHeight,
  playerPanelWidth,
  playerPanelX,
  playerPanelY,
  screenRatio,
} from './styles'

type Props = {
  player: Player
  cards: Card[]
  position: number
  gameOver: boolean
} & HTMLAttributes<HTMLDivElement>

export default function PlayerPanel({
  player,
  cards,
  position,
  gameOver,
  ...props
}: Props) {
  const playerInfo = usePlayer(player.id)
  const isFirstPlayer = useGame<Game>()?.firstPlayer == player.id
  const { t } = useTranslation()
  return (
    <div
      css={[panelPlayerStyle(colors[player.id]), positionCss(position)]}
      {...props}
    >
      <Avatar
        css={avatarStyle}
        playerId={player.id}
        speechBubbleProps={{ direction: SpeechBubbleDirection.BOTTOM_RIGHT }}
      />
      <MoneyCounter css={moneyStyle} money={player.points}/> 
      {isFirstPlayer && <FirstPlayerToken/>}
      <h2 css={nameStyle}>
        {playerInfo?.name === undefined
          ? //   ? getPlayerName(player.id, t)
            'Pseudo' //TODO
          : playerInfo?.name}
      </h2>
      {!gameOver && <PlayerTimer playerId={player.id} css={timerStyle} />}
      <GamePoints playerId={player.id} css={gamePointCss} />
    </div>
  )
}

const positionCss = (playerPosition: number) => css`
  position: absolute;
  left: ${playerPanelX(playerPosition)}em;
  top: ${playerPanelY(playerPosition)}em;
`

const nameStyle = css`
  position: absolute;
  top: 0.3em;
  left: 3.1em;
  max-width: 7.3em;
  font-size: 2.4em;
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-family: 'Mulish', sans-serif;
`

const timerStyle = css`
  position: absolute;
  top: 1.6em;
  left: 3.1em;
  font-size: 2.5em;
`

const panelPlayerStyle = (color: string) => css`
  border: 0.5em solid ${color};
  border-radius: 2em;
  width: ${playerPanelWidth * screenRatio}em;
  height: ${playerPanelHeight}em;
`

const avatarStyle = css`
  position: absolute;
  top: 1em;
  left: 1em;
  border-radius: 100%;
  height: 6em;
  width: 6em;
  color: black;
`

const moneyStyle = css`
  position: absolute;
  font-size: 0.7em;
  bottom: 1em;
  right: 1em;
`

// const tokenStyle = css`
//   position: absolute;
//   top: 1em;
//   right: 1em;
//   height: 5em;
//   width: 5em;
//   color: black;
// `

const gamePointCss = css`
  position: absolute;
  top: 1.6em;
  left: 3.1em;
  font-size: 2.5em;
`
