import blue_ambush from './cards/blue-ambush.jpg'
import blue_archer from './cards/blue-archer.jpg'
import blue_assassination from './cards/blue-assassination.jpg'
import blue_back from './cards/blue-back.jpg'
import blue_conspiracy from './cards/blue-conspiracy.jpg'
import blue_decree from './cards/blue-decree.jpg'
import blue_heir from './cards/blue-heir.jpg'
import blue_lord from './cards/blue-lord.jpg'
import blue_shapeshifter from './cards/blue-shapeshifter.jpg'
import blue_soldier from './cards/blue-soldier.jpg'
import blue_spy from './cards/blue-spy.jpg'

import green_ambush from './cards/green-ambush.jpg'
import green_archer from './cards/green-archer.jpg'
import green_assassination from './cards/green-assassination.jpg'
import green_back from './cards/green-back.jpg'
import green_conspiracy from './cards/green-conspiracy.jpg'
import green_decree from './cards/green-decree.jpg'
import green_heir from './cards/green-heir.jpg'
import green_lord from './cards/green-lord.jpg'
import green_shapeshifter from './cards/green-shapeshifter.jpg'
import green_soldier from './cards/green-soldier.jpg'
import green_spy from './cards/green-spy.jpg'

import yellow_ambush from './cards/yellow-ambush.jpg'
import yellow_archer from './cards/yellow-archer.jpg'
import yellow_assassination from './cards/yellow-assassination.jpg'
import yellow_back from './cards/yellow-back.jpg'
import yellow_conspiracy from './cards/yellow-conspiracy.jpg'
import yellow_decree from './cards/yellow-decree.jpg'
import yellow_heir from './cards/yellow-heir.jpg'
import yellow_lord from './cards/yellow-lord.jpg'
import yellow_shapeshifter from './cards/yellow-shapeshifter.jpg'
import yellow_soldier from './cards/yellow-soldier.jpg'
import yellow_spy from './cards/yellow-spy.jpg'

import grey_ambush from './cards/grey-ambush.jpg'
import grey_archer from './cards/grey-archer.jpg'
import grey_assassination from './cards/grey-assassination.jpg'
import grey_back from './cards/grey-back.jpg'
import grey_conspiracy from './cards/grey-conspiracy.jpg'
import grey_decree from './cards/grey-decree.jpg'
import grey_heir from './cards/grey-heir.jpg'
import grey_lord from './cards/grey-lord.jpg'
import grey_shapeshifter from './cards/grey-shapeshifter.jpg'
import grey_soldier from './cards/grey-soldier.jpg'
import grey_spy from './cards/grey-spy.jpg'

import red_ambush from './cards/red-ambush.jpg'
import red_archer from './cards/red-archer.jpg'
import red_assassination from './cards/red-assassination.jpg'
import red_back from './cards/red-back.jpg'
import red_conspiracy from './cards/red-conspiracy.jpg'
import red_decree from './cards/red-decree.jpg'
import red_heir from './cards/red-heir.jpg'
import red_lord from './cards/red-lord.jpg'
import red_shapeshifter from './cards/red-shapeshifter.jpg'
import red_soldier from './cards/red-soldier.jpg'
import red_spy from './cards/red-spy.jpg'
import lance from './lance.png'
import money from './coin.png'
import firstPlayerToken from './firstPlayerToken.png'

const Images = {
  blue_ambush,
  blue_archer,
  blue_assassination,
  blue_back,
  blue_conspiracy,
  blue_decree,
  blue_heir,
  blue_lord,
  blue_shapeshifter,
  blue_soldier,
  blue_spy,
  green_ambush,
  green_archer,
  green_assassination,
  green_back,
  green_conspiracy,
  green_decree,
  green_heir,
  green_lord,
  green_shapeshifter,
  green_soldier,
  green_spy,
  red_ambush,
  red_archer,
  red_assassination,
  red_back,
  red_conspiracy,
  red_decree,
  red_heir,
  red_lord,
  red_shapeshifter,
  red_soldier,
  red_spy,
  grey_ambush,
  grey_archer,
  grey_assassination,
  grey_back,
  grey_conspiracy,
  grey_decree,
  grey_heir,
  grey_lord,
  grey_shapeshifter,
  grey_soldier,
  grey_spy,
  yellow_ambush,
  yellow_archer,
  yellow_assassination,
  yellow_back,
  yellow_conspiracy,
  yellow_decree,
  yellow_heir,
  yellow_lord,
  yellow_shapeshifter,
  yellow_soldier,
  yellow_spy,
  lance,
  money,
  firstPlayerToken
}

export default Images
