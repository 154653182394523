import { Rules, SecretInformation } from '@gamepark/rules-api'
import Game from '../Game'
import Move, {
  AddToken,
  isPlaceCardView,
  MoveType,
  PlaceCardView,
  RevealCard,
} from '../moves/Move'
import { isNotGameOptions, OriflammeOptions } from '../OriflammeOptions'
import Color from '../Color'
import Phase from '../Phase'
import Card, { createCards, isHandLocation, isLineLocation } from '../Card'
import {
  arrangeCardsInHand,
  getCardVisibleOnPointer,
  getLine,
  getLineLength,
  getPossiblePlaces,
  moveAllCardsToRight,
} from '../Line'
import CardType, { cardTypes } from '../CardType'

/**
 * This class implements the rules of the board game.
 * It must follow Game Park "Rules" API so that the Game Park server can enforce the rules.
 */
export default class PlacementRules extends Rules<Game, Move, Color> {
  getActivePlayer(): Color {
    return this.state.currentPlayer
  }

  getNextPlayer(color: Color): Color {
    const index = this.state.players.findIndex((player) => player.id === color)
    return this.state.players[(index + 1) % this.state.players.length].id
  }

  /**
   * @deprecated
   * @param color
   * @returns NB card of the player given in param
   */
  getPlayerNbCards(color: Color): number {
    return this.getPlayerHand(color).length
  }

  getPlayerHand(color: Color): Card[] {
    return this.state.cards.filter(
      (card) => card.color === color && isHandLocation(card.location),
    )
  }

  /**
   * Return the exhaustive list of moves that can be played.
   * This is used for 2 features:
   * - security (preventing unauthorized moves from being played);
   * - "Dummy players": when a player leaves a game, it is replaced by a "Dummy" that plays random moves, allowing the other players to finish the game.
   * If the game allows a very large (or infinite) number of moves, instead of implementing this method, you can implement instead:
   * - isLegal(move: Move):boolean, for security; and
   * - A class that implements "Dummy" to provide a custom Dummy player.
   */
  getLegalMoves(playerId: Color): Move[] {
    //if it's not player in arg turn : return []
    if (this.getActivePlayer() !== playerId) return []

    let possiblePlaces = getPossiblePlaces(playerId, this.game.cards)

    //create a array with all possible places * all possible cards
    return this.getPlayerHand(playerId).flatMap((card) =>
      possiblePlaces.map((location) => {
        return {
          //TODO build
          type: MoveType.placeCard,
          cardType: card.type!,
          cardColor: card.color,
          location: location,
        }
      }),
    )
  }

  /**
   * This is the one and only play where you will update the game's state, depending on the move that has been played.
   *
   * @param move The move that should be applied to current state.
   */
  play(move: Move): Move[] {
    let result: Move[] = []
    switch (move.type) {
      case MoveType.placeCard:
        //take the card
        const playedCard = isPlaceCardView(move)
          ? this.state.cards.find(
              (card) =>
                isHandLocation(card.location) &&
                card.location.x === move.origin.x &&
                card.color === move.cardColor,
            )!
          : this.state.cards.find(
              (card) =>
                card.type === move.cardType && card.color === move.cardColor,
            )!
        //move it, flip it
        playedCard.flipped = true
        playedCard.location = move.location
        //arrange line
        const line = getLine(this.state.cards)

        if (move.location.x == 0) moveAllCardsToRight(line)

        return [ {type: MoveType.changeActivePlayer}]
        
      case MoveType.changeActivePlayer:
        console.log('Change Active Player')
        //give hand to next player
        this.state.currentPlayer = this.getNextPlayer(this.state.currentPlayer)

        //if it's first player then change phase and give first player marker to a new player
        if (this.state.currentPlayer == this.state.firstPlayer) {
          return [{type: MoveType.changePhase}]
        }
        break

      case MoveType.changePhase:
        console.log('Change Phase')

        this.state.phase = Phase.Resolution
        return [{ type: MoveType.initNewPhase }]
      
      case MoveType.initNewPhase:
        console.log('Init new Phase')

        this.state.round += 1
        this.state.firstPlayer = this.getNextPlayer(this.state.firstPlayer)
        this.state.currentPlayer = this.state.firstPlayer
        break
        
    }
    return []
  }
}
