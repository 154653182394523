/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import { CardInLine } from '@gamepark/oriflamme/Card'
import { getLineLength } from '@gamepark/oriflamme/Line'
import Move, { MoveType } from '@gamepark/oriflamme/moves/Move'
import { HTMLAttributes, useEffect, useState } from 'react'
import { useDrop, XYCoord } from 'react-dnd'
import { getMargin } from './LineDisplay'
import { cardHeight, cardInLineHeight, cardInlineRatio, cardInLineWidth, hOverlap, ITEM, maxMarginBetweenCards, vOverlap } from './styles'
import { MoveCard } from '@gamepark/oriflamme/moves/Move'

type Props = {
  cards: CardInLine[]
  legalMoves: Move[]
} & HTMLAttributes<HTMLDivElement>

export function getDecreeMove(cards: CardInLine[], legalMoves: Move[], card: CardInLine, offset: XYCoord, verbose=false) : MoveCard | undefined {

const letterbox = document.getElementById('letterbox')!.getBoundingClientRect()
const ratio = 100/letterbox.height //em/px

let offsetXem = offset.x * ratio
if (verbose) console.log("offsetXem", offsetXem)

//correction due to card.location.z
offsetXem = (offsetXem<=0)? offsetXem - hOverlap*cardInLineWidth * (card.location.z-1) 
                          : offsetXem + hOverlap*cardInLineWidth * (card.location.z-1)

const fromX = card.location.x

const lineLength = getLineLength(cards)

const margin = getMargin(lineLength)*cardInlineRatio

const semiCardWidth = (margin+cardInLineWidth)/2
if (verbose) console.log("semiCardWidth", semiCardWidth)

const firstMargin = (cardInLineWidth-semiCardWidth) /2
if (verbose) console.log("firstMargin", firstMargin)

//offsetXSemiPlace is an absolute value (always positive)
let offsetXSemiPlace = Math.ceil((Math.abs(offsetXem)-firstMargin)/semiCardWidth)
if (verbose) console.log("offsetXSemiPlace", offsetXSemiPlace)

const offsetXPlace = (offsetXem<=0) ? -Math.floor(offsetXSemiPlace/2) : Math.ceil(offsetXSemiPlace/2)
if (verbose) console.log("offsetXPlace", offsetXPlace)

//limit final offset from 1 to lineLengh+1
const finalOffset = Math.min(Math.max(1, fromX + offsetXPlace), lineLength+1)

const moveOnCard = ((fromX + offsetXPlace)<1 || (fromX + offsetXPlace)>lineLength) ? false : offsetXSemiPlace%2==0

const move = legalMoves.find(move=>move.type === MoveType.moveCard && 
                                   move.from == card.location && 
                                   move.to == finalOffset &&
                                   move.on == moveOnCard)

if (verbose) console.log(move)

return move as MoveCard
}

export default function MoveCardDropArea({ cards, legalMoves, ...props }: Props) {
  const [{ dragging, over }, ref] = useDrop({
    accept: ITEM,
    canDrop: (card: CardInLine, monitor) => {
      const sourceClientOffset = monitor.getDifferenceFromInitialOffset()
      if (!sourceClientOffset) return false
      const move = getDecreeMove(cards, legalMoves, card, sourceClientOffset)
      return move!==undefined
    },
    collect: (monitor) => ({
      dragging: monitor.getItemType() === ITEM,
      over: monitor.isOver(),
    }),
    drop: (card: CardInLine, monitor) => {
      const sourceClientOffset = monitor.getDifferenceFromInitialOffset()!
      return getDecreeMove(cards, legalMoves, card, sourceClientOffset)
    },
  })

  //Hack to delay the area display to prevent it from killing dragging 
  const [hideArea, setHideArea] = useState(true)
  useEffect(()=>{
    setTimeout(()=>setHideArea(!dragging))
  }, [dragging])

  return <div css={[areaCss, hideArea && areaHiddenCss]} ref={ref} {...props} />
}

const hoverStyle = css`
  transform: scale(1.2);
`

const areaCss = css`
  position: absolute;

  width: 100%;
  height: ${cardHeight + (2 * vOverlap * cardHeight)}em;
  // background-color: rgba(255, 255, 255, 0.5);
  left: 0;
  bottom: ${(70 - cardHeight / 2) / cardInlineRatio - (2) * vOverlap * cardHeight}em;


  transform-style: preserve-3d;
  transform: translateZ(0);
  -webkit-font-smoothing: subpixel-antialiased;
  transition: transform 1s ease-in-out;
  z-index:100;
`

const areaHiddenCss = css`
  display: none;
`
