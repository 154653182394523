import Color from './Color'

type Player = {
  id: Color
  points: number
}

export function earnToken(player: Player, nb: number): void {
  player.points += nb
}

/**
 * function to remove points to a player, never more than he has, and return how many points he really lost
 * @param player
 * @param nb
 * @returns
 */
export function loseToken(player: Player, nb: number): number {
  nb = Math.min(nb, player.points)
  player.points -= nb
  return nb
}

export function findPlayer(players: Player[], color: Color): Player {
  return players.find((player) => player.id === color)!
}

export default Player
