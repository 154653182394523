import {
  CardLocation,
  HandLocation,
  LineLocation,
} from '../Card'
import CardType from '../CardType'
import Color from '../Color'
/**
 * A Move in a game is an operation that change the state of the game.
 * The moves are serialized using JSON, so you must use plain objects (or strings).
 * The moves are saved in the database, so you must keep them as light as you can.
 * Finally, the moves are used to animate the game on the front end. It is highly recommended to create very small moves,
 * each representing a simple move of material, or a simple change in the state of the game (change game phase, change active player...)
 *
 * Recommended structure: Move = Move1 | Move2 | Move3... Each move as a type, using an enum, so that each move type can easily be identified.
 */
export function isPlaceCardView(
  move: PlaceCard | PlaceCardView,
): move is PlaceCardView {
  return (move as PlaceCardView).origin !== undefined
}

export function isRevealCardView(
  move: RevealCard | RevealCardView,
): move is RevealCardView {
  return (move as RevealCardView).cardType !== undefined
}

export function isShowCardView(
  move: ShowCard | ShowCardView,
): move is ShowCardView {
  return (move as ShowCardView).cardType !== undefined
}

export function isMoveWithTarget(move: Move): move is MoveWithTarget {
  return (move as MoveWithTarget).target !== undefined //TODO change to move.type
}

export enum MoveType {
  placeCard = 1,
  revealCard,
  addToken,
  movePointer,
  getTokens,
  killCard,
  discard,
  steal,
  showCard,
  copyType,
  pass,
  moveCard,
  changePhase,
  changeActivePlayer,
  initNewPhase
}

//put a card on a line position
export type PlaceCard = {
  type: MoveType.placeCard
  cardColor: Color
  cardType: CardType
  location: LineLocation
}

export type PlaceCardView = Omit<PlaceCard, 'cardType'> & {
  origin: HandLocation
}

//flip a card
export type RevealCard = {
  type: MoveType.revealCard
  location: LineLocation
}

export type RevealCardView = RevealCard & {
  cardType: CardType
}

//to use before be killed, just to show card without any consequence
export type ShowCard = {
  type: MoveType.showCard
  location:LineLocation
}

export type ShowCardView = ShowCard & {
  cardType: CardType
}

//add a token on a card
export type AddToken = {
  type: MoveType.addToken
  location: CardLocation
}

//move pointer at position...
export type MovePointer = {
  type: MoveType.movePointer
}

export type ChangePhase = {
  type: MoveType.changePhase
}

export type ChangeActivePlayer = {
  type: MoveType.changeActivePlayer
}

export type InitNewPhase = {
  type: MoveType.initNewPhase
}

export type CopyType = {
  type: MoveType.copyType
  target: LineLocation
}

//receive tokens
export type GetTokens = {
  type: MoveType.getTokens
  from?: Color
  to: Color
  nb: number
}

//target a card in order to discard it
export type KillCard = {
  type: MoveType.killCard
  target: LineLocation
}

//target a card in order to steal it a coin
export type Steal = {
  type: MoveType.steal
  target: LineLocation
  to: Color
}

//target a card in order to move it (to is the number just before where you want put the card)
export type MoveCard = {
  type: MoveType.moveCard
  from: LineLocation
  to: number
  on?: boolean //yes or no you want put this card on an other
}

//place a card in discard
export type Discard = {
  type: MoveType.discard
  location : LineLocation
}

export type Pass = {
  type: MoveType.pass
}

type Move =
  | PlaceCard
  | PlaceCardView
  | RevealCard
  | RevealCardView
  | ShowCard
  | ShowCardView
  | Discard
  | GetTokens
  | AddToken
  | MovePointer
  | ChangePhase
  | InitNewPhase
  | ChangeActivePlayer
  | MoveWithTarget
  | MoveCard
  | Pass


export type MoveWithTarget =
| KillCard
| Steal
| CopyType

export default Move
