import { Rules } from '@gamepark/rules-api'
import Card, { CardInLine } from '../Card'
import Color from '../Color'
import Game from '../Game'
import { getCardVisibleOnPointer, getLineLength } from '../Line'
import Move, { MoveType } from '../moves/Move'
import Phase from '../Phase'

export default abstract class OriflammeCardsRules extends Rules<
Game,
Move,
Color
> {  
  getActivePlayer(): Color {
  return getCardVisibleOnPointer(this.state.cards, this.state.pointer)!.color
}
  /**
   *
   * @returns a card array with all cards that can be targeted on that state
   */
  getTargets(): CardInLine[] {
    return []
  }

  getAutomaticMoves(): Move[] {
    console.log('getAutomaticMoves')
    const card = getCardVisibleOnPointer(this.state.cards, this.state.pointer)
    // if pointer is not on a card, it should be the end of round (or there is a bug)
    
    if (card && this.getLegalMoves(card.color).length == 0){
      return [ { type: MoveType.movePointer }]
    } else return super.getAutomaticMoves()
  }
}
