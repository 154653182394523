/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import Card from '@gamepark/oriflamme/Card'
import Color from '@gamepark/oriflamme/Color'
import Game from '@gamepark/oriflamme/Game'
import Phase from '@gamepark/oriflamme/Phase'
import { useGame, usePlay } from '@gamepark/react-client'
import { Hand } from '@gamepark/react-components'
import CardDisplay from './CardDisplay'
import { cardHeight, cardWidth, ITEM, screenRatio } from './styles'

type Props = {
  cards: Card[]
  player: Color
}

export default function PlayerHand({ cards, player }: Props) {
  const play = usePlay()
  const game = useGame<Game>()

  const isActive = (game?.phase === Phase.Placement && game.currentPlayer == player)

  return (
    <>
      <Hand
        css={handCss}
        getItemProps={(index) => {
          return {
            drag: {
              type: ITEM,
              canDrag: isActive,
              item: cards[index],
              drop: (move) => play(move),
            },
            hoverStyle,
          }
        }}
        sizeRatio={cardWidth / cardHeight}
      >
        {cards.map((card) => (
          <CardDisplay
            key={card.color.toString() + '_' + card.type?.toString()}
            card={card}
            covered={false}
          />
        ))}
      </Hand>
    </>
  )
}

const hoverStyle = css`
  transform: scale(1.2);
`

const handCss = css`
  position: absolute;
  width: ${cardWidth}em;
  height: ${cardHeight}em;
  bottom: 5em;
  left: ${screenRatio * 50 - cardWidth / 2}em;
`
