/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import Images from './images/Images'
import { HTMLAttributes } from 'react'

type Props = HTMLAttributes<HTMLDivElement>

export default function FirstPlayerToken({ ...props }: Props) {
  return <div css={[tokenCss]} {...props}  />
}

const tokenCss = css`
display: inline-block;
position: absolute;
bottom: -1em;
left: -1em;
height: 5em;
width: 7em;
background-image: url(${Images.firstPlayerToken});
background-size: 100% 100%;
background-repeat: no-repeat;
  }
`
