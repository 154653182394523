import { LineLocation, LocationType } from '../../Card'

import Color from '../../Color'
import { getCardAtLocation, getCardVisibleOnPointer, 
  moveCardsAfterDiscardingOne, getVisibleLine, moveCardsBeforeInsertingOne } from '../../Line'
import Move, { MoveCard, MoveType } from '../../moves/Move'
import OriflammeCardsRules from '../OriflammeCardsRules'

/**
 * This class implements the rules of the board game.
 * It must follow Game Park "Rules" API so that the Game Park server can enforce the rules.
 */
export default class AmbushRules extends OriflammeCardsRules {
  /**
   * Return the exhaustive list of moves that can be played.
   * This is used for 2 features:
   * - security (preventing unauthorized moves from being played);
   * - "Dummy players": when a player leaves a game, it is replaced by a "Dummy" that plays random moves, allowing the other players to finish the game.
   * If the game allows a very large (or infinite) number of moves, instead of implementing this method, you can implement instead:
   * - isLegal(move: Move):boolean, for security; and
   * - A class that implements "Dummy" to provide a custom Dummy player.
   */
  getLegalMoves(playerId: Color): MoveCard[] {
    //if it's not player in arg turn : return []
    if (this.getActivePlayer() !== playerId) return []

    const visibleLine = getVisibleLine(this.state.cards)
    const possibleTargets = visibleLine.filter(card=> card.location.x != this.state.pointer)
    const possiblePlacesBetweenCards = visibleLine.map(card=>card.location.x)
    possiblePlacesBetweenCards.push(visibleLine.length+1)

    return possibleTargets.flatMap((cardToMove)=> {
      const possibleOnCardPlaces = possibleTargets.filter(card=>card.color===cardToMove.color && card!==cardToMove)
      const possibleMovesOnCard: MoveCard[] = possibleOnCardPlaces.map(card => ({type:MoveType.moveCard, from:cardToMove.location as LineLocation, to: card.location.x, on:true})
      )

      //forbid to move a card that was alone before or after itself (that would result of doing nothing)
      const filteredPossiblePlaces  = possiblePlacesBetweenCards.filter(to=>(to!=cardToMove.location.x && to!=cardToMove.location.x+1) || cardToMove.location.z!=1)
      
      const possibleMovesBetweenCards: MoveCard[] = filteredPossiblePlaces.map(location=>({type:MoveType.moveCard, from:cardToMove.location as LineLocation, to: location, on:false})
      )
      return [...possibleMovesBetweenCards, ...possibleMovesOnCard]
    }
    )

  }

  /**
   * This is the one and only play where you will update the game's state, depending on the move that has been played.
   *
   * @param move The move that should be applied to current state.
   */
  play(move: Move): Move[] {
    switch (move.type) {
      case MoveType.moveCard:
        console.log('moveCard', move)
        const decree = getCardVisibleOnPointer(this.state.cards, this.state.pointer)!
        const cardToMove = getCardAtLocation(this.state.cards, move.from)!
        //if the cardToMove is alone, we will have to rebuild the line
        const isBrokenLine = move.from.z===1


        if (move.on){
          const cardToCover = getCardVisibleOnPointer(this.state.cards, move.to)!
          cardToMove.location = {...cardToCover.location, z:cardToCover.location.z+1}
        } else {
          moveCardsBeforeInsertingOne(this.game.cards, move.to)
          cardToMove.location = { type: LocationType.line , x: move.to, z:1}
        }
        
        if (isBrokenLine) moveCardsAfterDiscardingOne(this.state.cards, move.from.x)

        return [{ type: MoveType.discard, location:decree.location }] 

      }
    return []
  }

  /**
   * Here you can return the moves that should be automatically played when the game is in a specific state.
   * Here is an example from monopoly: you roll a dice, then move you pawn accordingly.
   * A first solution would be to do both state updates at once, in a "complex move" (RollDiceAndMovePawn).
   * However, this first solution won't allow you to animate step by step what happened: the roll, then the pawn movement.
   * "getAutomaticMoves" is the solution to trigger multiple moves in a single action, and still allow for step by step animations.
   * => in that case, "RollDice" could set "pawnMovement = x" somewhere in the game state. Then getAutomaticMove will return "MovePawn" when
   * "pawnMovement" is defined in the state.
   * Of course, you must return nothing once all the consequences triggered by a decision are completed.
   * VERY IMPORTANT: you should never change the game state in here. Indeed, getAutomaticMove will never be called in replays, for example.
   *
   * @return The next automatic consequence that should be played in current game state.
   */
  getAutomaticMoves(): Move[] {
    return super.getAutomaticMoves()
  }
}

