import Color from './Color'
import Card, {
  LocationType,
  LineLocation,
  isLineLocation,
  CardInLine,
  isCardInLine,
  CardLocation,
} from './Card'
import equal from 'fast-deep-equal'

//return all visible cards on line (ordered)
export function getVisibleLine(allCards: Card[]): CardInLine[] {
  let result: Map<number,CardInLine> = new Map()
  let cards = getLine(allCards)
  cards.forEach((card) => {
    if (
      !result.has(card.location.x) ||
      result.get(card.location.x)!.location.z < card.location.z
    ) {
      result.set(card.location.x, card)
    }
  })
  let resultArray = Array.from(result.values())
  return resultArray.sort((a, b) => a.location.x - b.location.x)
}

//return all card in line
export function getLine(allCards: Card[]): CardInLine[] {
  return allCards.filter((card) =>
    isLineLocation(card.location),
  ) as CardInLine[]
}

export function getLineLength(allCards: Card[]): number {
  return allCards
    .filter((card) => isLineLocation(card.location))
    .reduce(
      (max: number, current: Card) => Math.max(max, current.location.x),
      0,
    )
}

/**
 * Determine where a player can play
 * @param player
 * @returns array of possible places
 */
export function getPossiblePlaces(
  player: Color,
  allCards: Card[],
): LineLocation[] {
  let result: LineLocation[] = []

  let ownedCards = getVisibleLine(allCards).filter(
    (card) => card.color === player,
  )

  result = ownedCards.map((card) => {
    return { ...card.location, z: card.location.z + 1 }
  })

  //add first and last place
  let lineLength = getLineLength(allCards)
  result.push({ type: LocationType.line, x: 0, z: 1 })
  if (lineLength > 0)
    result.push({ type: LocationType.line, x: lineLength + 1, z: 1 })

  return result
}

/**
 * function to arrange cards in a hand from 1 to cards.length
 * @param cards
 */
export function arrangeCardsInHand(cards: Card[]): void {
  let index = 1

  cards.sort((a, b) => (a.location.x > b.location.x ? 1 : -1))
  //TODO handle location.z
  cards.forEach((card) => (card.location.x = index++))
}

/* function useful after have added a card at 0 index*/
export function moveAllCardsToRight(cards: Card[]): void {
  cards.forEach((card) => card.location.x++)
}

/* function useful after have discarded a card at discardedIndex*/
export function moveCardsAfterDiscardingOne(
  cards: Card[],
  discardedIndex: number,
): void {
  cards.forEach((card) => {
    if (card.location.x > discardedIndex) card.location.x--
  })
}

/* function to move cards one position to right in order to inserting one at insertedIndex*/
export function moveCardsBeforeInsertingOne(
  cards: Card[],
  insertedIndex: number,
): void {
  cards.forEach((card) => {
    if (card.location.x >= insertedIndex) card.location.x++
  })
}

/**
 *
 * @returns Card visible at pointer in line
 */
export function getCardVisibleOnPointer(
  cards: Card[],
  pointer: number,
): CardInLine | undefined {
  const cardsInLine = getVisibleLine(cards)
  return getCardOnPosition(cardsInLine, pointer)
}

export function getCardOnPosition<T extends Card>(
  cards: T[],
  position: number,
): T | undefined {
  // console.log(cards, position)
  return cards.find((card) => card.location.x === position)
}

/**
 *
 * @param cards
 * @param location
 * @returns one Card in param cards corresponding to location in param
 */
export function getCardAtLocation(cards: Card[], location: CardLocation): Card | undefined{
  return cards.find((card) => equal(card.location, location))
  // const result = cards.find((card) => equal(card.location, location))
  // if (result) return result
  // else throw new Error(`${location} have not been found. Should not happen.`)
}

export function getBeforeAndAfterCards(
  cards: Card[],
  position: number,
): CardInLine[] {
  const line = getVisibleLine(cards)

  const result: CardInLine[] = []

  for (let index of [-1,+1]){
    const card = getCardOnPosition(line, position + index)
    if (card) result.push(card)
  }
  
  return result
}
