/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import Images from './images/Images'
import Card, { isCardInLine, LocationType } from '@gamepark/oriflamme/Card'
import CardType from '@gamepark/oriflamme/CardType'
import Color from '@gamepark/oriflamme/Color'
import { HTMLAttributes } from 'react'
import { ITEM, cardHeight, cardWidth, coinSize, shineEffect } from './styles'
import MoneyCounter from './MoneyCounter'
import { getCardAtLocation } from '@gamepark/oriflamme/Line'
import { Draggable } from '@gamepark/react-components'
import { DraggableProps } from '@gamepark/react-components/dist/Draggable/Draggable'
import { usePlay } from '@gamepark/react-client'

type Props = {
  card: Card,
  covered: boolean,
  highlighted?: boolean
} & Omit<DraggableProps,'type'>

export default function CardDisplay({ card, highlighted, covered, ...props }: Props) {
  let cardType = card.type ? CardType[card.type] : 'back'
  let cardName = (Color[card.color] + '_' + cardType).toLowerCase()
  let cardBack = (Color[card.color] + '_back').toLowerCase()
  const play = usePlay()

  return (
    // a block rotated if flipped
    <Draggable type={ITEM} item={card} canDrag={false} drop={play} {...props}>
      <div
        css={[
          cardCss,
          (card.flipped || !card.type) && rotateCss
        ]}
      >

        {/* a face if known but rotated */}
        {card.type && <div css={[cardFaceCss(cardName),
                                 rotateCss]} />}

        {/* a cardback rotated (and on the first face) becoming transparent on hover*/}
        <div
          css={[
            cardFaceCss(cardBack),
            rotateCss,
            card.flipped && card.type && hoverCss,
            highlighted && shineEffect,
          ]}
        />

        {/* a facecard not rotated */}
        {card.type && <div css={[cardFaceCss(cardName), highlighted && shineEffect]} />}
      </div>

      {/* a money counter on top */}
      {isCardInLine(card) && !!card.points && <MoneyCounter money={card.points} css={(covered) ? coveredCss : insideCss} />}
    </Draggable>
  )
}

const cardCss = css`
  width: ${cardWidth}em;
  height: ${cardHeight}em;
  transform-style: preserve-3d;
  transition: transform 0.2s ease-in-out;
`

const cardFaceCss = (cardName: string) => css`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(${Images[cardName]});

  border-radius: 1.5em;
  box-shadow: 0 0 1em black, 0 0 1em black;

  background-size: 100%;
  backface-visibility: hidden;
`
const rotateCss = css`
  transform: rotateY(180deg);
`

const hoverCss = css`
  &:hover {
    opacity: 0.4;
  }
`

const insideCss = css`
  position: absolute;
  top: ${(cardHeight / 2 - coinSize / 2)}em;
  left: ${(cardWidth / 2 - coinSize / 2)}em;
`

const coveredCss = css`
  position: absolute;
  font-size: ${0.6}em;
  top: 0em;
  left: ${(cardWidth / 2)/0.6 - (coinSize/2)}em;
`
