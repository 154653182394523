import { Rules, SecretInformation } from '@gamepark/rules-api'
import Game from './Game'
import Move, {
  isPlaceCardView,
  isRevealCardView,
  MoveType,
  PlaceCardView,
  RevealCard,
  RevealCardView,
} from './moves/Move'
import { isNotGameOptions, OriflammeOptions } from './OriflammeOptions'
import Color from './Color'
import Phase from './Phase'
import Card, { createCards, isHandLocation, isLineLocation } from './Card'
import PlacementRules from './phases/PlacementRules'
import ResolutionRules from './phases/ResolutionRules'
import { getCardAtLocation } from './Line'

/**
 * This class implements the rules of the board game.
 * It must follow Game Park "Rules" API so that the Game Park server can enforce the rules.
 */
export default class OriflammeRules extends Rules<Game, Move, Color>
  implements SecretInformation<Game, Move, Move, Color> {
  /**
   * This constructor is called when the game "restarts" from a previously saved state.
   * @param state The state of the game
   */
  constructor(state: Game)

  /**
   * This constructor is called when a new game is created. If your game has options, or a variable number of players, it will be provided here.
   * @param options The options of the new game
   */
  constructor(options: OriflammeOptions)
  /**
   * In here you must code the construction of your class. Use a "typeguard" to distinguish a new game from a restored game.
   * @param arg The state of the game, or the options when starting a new game
   */
  constructor(arg: Game | OriflammeOptions) {
    if (isNotGameOptions(arg)) {
      //game already launched
      super(arg)
    } else {
      //create new game
      const newGame = {
        players: arg.players.map((playerOptions) => ({
          id: playerOptions.id,
          points: 1,
        })),
        firstPlayer: arg.players[0].id,
        currentPlayer: arg.players[0].id,
        round: 1,
        cards: createCards(
          arg.players.map((playerOptions) => playerOptions.id),
        ),
        pointer: 1,
        phase: Phase.Placement,
      }

      super(newGame)
    }
  }

  delegate(): Rules<Game, Move, Color> | undefined {
    switch (this.state.phase) {
      case Phase.Placement:
        return new PlacementRules(this.state)
      case Phase.Resolution:
        return new ResolutionRules(this.state)
      default:
        return undefined
    }
  }

  /**
   * @deprecated
   * @param color
   * @returns NB card of the player given in param
   */
  getPlayerNbCards(color: Color): number {
    return this.getPlayerHand(color).length
  }

  getPlayerHand(color: Color): Card[] {
    return this.state.cards.filter(
      (card) => card.color === color && isHandLocation(card.location),
    )
  }

  /**
   * This is the one and only play where you will update the game's state, depending on the move that has been played.
   *
   * @param move The move that should be applied to current state.
   */
  play(move: Move): Move[] {
    switch (move.type) {
    }
    return super.play(move)
  }

  /**
   * Here you can return the moves that should be automatically played when the game is in a specific state.
   * Here is an example from monopoly: you roll a dice, then move you pawn accordingly.
   * A first solution would be to do both state updates at once, in a "complex move" (RollDiceAndMovePawn).
   * However, this first solution won't allow you to animate step by step what happened: the roll, then the pawn movement.
   * "getAutomaticMoves" is the solution to trigger multiple moves in a single action, and still allow for step by step animations.
   * => in that case, "RollDice" could set "pawnMovement = x" somewhere in the game state. Then getAutomaticMove will return "MovePawn" when
   * "pawnMovement" is defined in the state.
   * Of course, you must return nothing once all the consequences triggered by a decision are completed.
   * VERY IMPORTANT: you should never change the game state in here. Indeed, getAutomaticMove will never be called in replays, for example.
   *
   * @return The next automatic consequence that should be played in current game state.
   */
  getAutomaticMoves(): Move[] {
    return super.getAutomaticMoves()
  }

  getPlayerView(playerId?: Color): Game {
    return {
      ...this.state,
      cards: this.state.cards.map((card) => {
        if (
          card.color != playerId &&
          (card.flipped || !isLineLocation(card.location))
        ) {
          //i'don't know card
          // remove type before return
          const { type, ...secretCard } = card
          return secretCard
        }
        return card
      }),
    }
  }

  // for spectators
  getView(): Game {
    return this.getPlayerView()
  }

  getMoveView(move: Move): Move {
    return this.getPlayerMoveView(move)
  }

  getPlayerMoveView(move: Move, playerId?: Color): Move {
    switch (move.type) {
      case MoveType.placeCard: {
        if (isPlaceCardView(move) || move.cardColor === playerId) return move //do not hide information if it's my move
        const card = this.state.cards.find(
          (card) =>
            card.type === move.cardType && card.color === move.cardColor,
        )!
        const moveView: PlaceCardView = {
          type: move.type,
          location: move.location,
          cardColor: move.cardColor,
          origin: card.location,
        }
        return moveView
      }
      case MoveType.revealCard:
        const card = getCardAtLocation(this.state.cards, move.location)!
        if (isRevealCardView(move) || card.color === playerId) return move
        const moveView: RevealCardView = {
          type: move.type,
          location: move.location,
          cardType: card.type!,
        }
        return moveView

      default:
        return move
    }
  }
}
