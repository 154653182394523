import { Rules } from '@gamepark/rules-api'
import Game from '../Game'
import Move, {
  AddToken,
  isRevealCardView,
  isShowCardView,
  MoveType,
  RevealCard,
} from '../moves/Move'
import Color from '../Color'
import Phase from '../Phase'
import Card, { CardInLine, isCardInLine, LineLocation, LocationType } from '../Card'
import {
  getCardAtLocation,
  getCardVisibleOnPointer,
  getLineLength,
  moveCardsAfterDiscardingOne,
} from '../Line'
import CardType from '../CardType'
import ArcherRules from './CardsRules/ArcherRules'
import AssassinationRules from './CardsRules/AssassinationRules'
import HeirRules from './CardsRules/HeirRules'
import LordRules from './CardsRules/LordRules'
import SoldierRules from './CardsRules/SoldierRules'
import SpyRules from './CardsRules/SpyRules'
import { earnToken, findPlayer, loseToken } from '../Player'
import OriflammeCardsRules from './OriflammeCardsRules'
import AmbushRules from './CardsRules/ArcherRules'
import ConspiracyRules from './CardsRules/ConspiracyRules'
import { countCardInDiscard } from '../UtilsCards'
import ShapeshifterRules from './CardsRules/ShapeshifterRules'
import DecreeRules from './CardsRules/DecreeRules'

/**
 * This class implements the rules of the board game.
 * It must follow Game Park "Rules" API so that the Game Park server can enforce the rules.
 */
export default class ResolutionRules extends Rules<Game, Move, Color> {
  delegate(): OriflammeCardsRules | undefined {
    const card = getCardVisibleOnPointer(this.state.cards, this.state.pointer)
    if (card === undefined) return
    if (card.flipped)       return
    switch (
      card.type
    ) {
      case CardType.Ambush:
        return new AmbushRules(this.state)
      case CardType.Archer:
        return new ArcherRules(this.state)
      case CardType.Assassination:
        return new AssassinationRules(this.state)
      case CardType.Conspiracy:
        return new ConspiracyRules(this.state)
      case CardType.Decree:
        return new DecreeRules(this.state)
      case CardType.Heir:
        return new HeirRules(this.state)
      case CardType.Lord:
        return new LordRules(this.state)
      case CardType.Shapeshifter:
        return new ShapeshifterRules(this.state)
      case CardType.Soldier:
        return new SoldierRules(this.state)
      case CardType.Spy:
        return new SpyRules(this.state)
      default:
        return
    }
  }

  getActivePlayer(): Color | undefined {
    return getCardVisibleOnPointer(this.state.cards, this.state.pointer)?.color
  }

  getTargets(): CardInLine[] {
    const delegate = this.delegate()
    if (delegate) return delegate.getTargets()
    else return []
  }

  /**
   * Return the exhaustive list of moves that can be played.
   * This is used for 2 features:
   * - security (preventing unauthorized moves from being played);
   * - "Dummy players": when a player leaves a game, it is replaced by a "Dummy" that plays random moves, allowing the other players to finish the game.
   * If the game allows a very large (or infinite) number of moves, instead of implementing this method, you can implement instead:
   * - isLegal(move: Move):boolean, for security; and
   * - A class that implements "Dummy" to provide a custom Dummy player.
   */
  getLegalMoves(playerId: Color): Move[] {
    //if it's not player in arg turn : return []
    if (this.getActivePlayer() !== playerId) return []

    let card = getCardVisibleOnPointer(this.state.cards, this.state.pointer)
    if (card?.flipped) {
      let reveal: RevealCard = {
        type: MoveType.revealCard,
        location: card.location,
      }
      let add: AddToken = {
        type: MoveType.addToken,
        location: card.location,
      }
      return [reveal, add]
    }
    return super.getLegalMoves(playerId)
  }

  /**
   * This is the one and only play where you will update the game's state, depending on the move that has been played.
   *
   * @param move The move that should be applied to current state.
   */
  play(move: Move): Move[] {
    let result: Move[] = []

    switch (move.type) {
      case MoveType.addToken: {
        console.log('addToken', move)
        const card = getCardAtLocation(this.state.cards, move.location)!
        card.points = card.points ? card.points + 1 : 1
        return [{ type: MoveType.movePointer }]
      }

      case MoveType.showCard:{
        console.log("showCard", move)
        const card = getCardAtLocation(this.state.cards, move.location)!
        card.flipped = false
        if (isShowCardView(move)) card.type = move.cardType

      return []
      }

      case MoveType.revealCard:{
        console.log('revealCard', move)
        const card = getCardAtLocation(this.state.cards, move.location)!
        card.flipped = false
        if (isRevealCardView(move)) card.type = move.cardType
        if (!card.points) card.points = 0
        // OK BUT WHY
        // const result = (card.type === CardType.Conspiracy || card.type === CardType.Ambush) ?
        // [
        //   {type: MoveType.getTokens,
        //   to: card.color,
        //   nb :card.type === CardType.Ambush
        //   ? 1
        //   : card.points * 2
        // },
        // {type: MoveType.discard, location: card.location as LineLocation},
        // { type: MoveType.movePointer, nb:0 }
        // ]
        // : [
        //   {
        //     type: MoveType.getTokens,
        //     to: card.color,
        //     nb:
        //       card.points,
        //   },
        // ]
        // card.points = 0
        // return result
        if (card.type === CardType.Conspiracy || card.type === CardType.Ambush)
        {
          result = [
            {type: MoveType.getTokens,
            to: card.color,
            nb :card.type === CardType.Ambush
            ? 1
            : card.points * 2
          },
          {type: MoveType.discard, location: card.location as LineLocation}
          ]
        } else {
          result = [
            {
              type: MoveType.getTokens,
              to: card.color,
              nb:
                card.points,
            },
          ]
        }
        card.points = 0
        return result
      }

      case MoveType.getTokens:
        console.log('getTokens', move)
        const money = move.from
          ? loseToken(findPlayer(this.state.players, move.from), move.nb)
          : move.nb
        earnToken(findPlayer(this.state.players, move.to), money)
        break

      case MoveType.killCard:
        console.log('killCard', move)
        const killer = getCardVisibleOnPointer(
          this.state.cards,
          this.state.pointer,
        )!
        const killed = getCardAtLocation(this.state.cards, move.target)! as CardInLine
        
        //the killed card is revealed
        if (killed.flipped && isCardInLine(killed)) result.push({type:MoveType.showCard, location:killed.location})

        //if killed card is ambush, killer is killed too, ambush owner gains 4 points (except if it killed itself)
        if (killed.type == CardType.Ambush)
          result.push(
            { type: MoveType.discard, location: killer.location},
            { type: MoveType.getTokens, nb: (killed.color != killer.color)? 4 : 1, to: killed.color },
          )

        //killed is discarded and killer gains 1 point
        result.push(
          { type: MoveType.discard, location: killed.location },
          {
            type: MoveType.getTokens,
            to: killer.color,
            nb: 1,
          },
        )
        // then remove assassination (except if it killed himself)
        if (killer.type == CardType.Assassination && killed != killer) {
          result.push({type: MoveType.discard, location: killer.location})
        }
        else result.push({ type: MoveType.movePointer })
        return result

      case MoveType.discard:{
        console.log('discard', move)
        const cardToDiscard : Card = getCardAtLocation(this.state.cards, move.location)!
        if (move.location.z === 1) {
          moveCardsAfterDiscardingOne(this.game.cards, move.location.x)
          if (this.state.pointer>move.location.x) this.state.pointer--
        }
        cardToDiscard.location = {type: LocationType.discard, x: countCardInDiscard(this.state.cards, cardToDiscard.color)}
        
        break
      }

      case MoveType.movePointer:
        console.log('movepointer', this.state.pointer)
        
        //empty choosentype on previous card:
        const card = getCardVisibleOnPointer(this.state.cards, this.state.pointer)

        if (card?.choosenType){
          card.choosenType = undefined
        }

        this.state.pointer += 1

        return []

        case MoveType.changePhase:
          console.log("changePhase", move)

          if (this.state.round == 6){
            this.state.phase = undefined
          }
          else {
            this.state.phase = Phase.Placement
          }
          return [{type:MoveType.initNewPhase}]
        case MoveType.initNewPhase:
          console.log('init new phase')
          this.state.pointer = 1

    }

    return super.play(move)
  }

  getAutomaticMoves(): Move[] {
    console.log('getAutomaticMoves')

  if (this.state.pointer > getLineLength(this.state.cards)) {
   return [ { type: MoveType.changePhase }]
  } else return super.getAutomaticMoves()
}
  
}
