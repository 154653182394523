/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import Images from './images/Images'
import useEfficientDragLayer from  '@gamepark/react-components/dist/Draggable/useEfficientDragLayer'
import Card, { CardInLine, LineLocation, LocationType } from '@gamepark/oriflamme/Card'
import Move, { isMoveWithTarget, KillCard, MoveCard, MoveType, MoveWithTarget } from '@gamepark/oriflamme/moves/Move'
import { useGame, usePlay } from '@gamepark/react-client'
import CardDropArea from './CardDropArea'
import CardDisplay from './CardDisplay'
import {
  cardHeight,
  cardInlineRatio,
  cardInLineWidth,
  cardWidth,
  colors,
  hOverlap,
  lanceRatio,
  maxMarginBetweenCards,
  screenRatio,
  vOverlap,
} from './styles'
import useLegalMoves from './useLegalMoves'
import usePossiblePlaces from './usePossiblePlaces'
import Game from '@gamepark/oriflamme/Game'
import Phase from '@gamepark/oriflamme/Phase'
import { getCardAtLocation, getCardVisibleOnPointer, getLineLength, getVisibleLine } from '@gamepark/oriflamme/Line'
import MoveCardDropArea, { getDecreeMove } from './MoveCardDropArea'

type Props = {
  cards: CardInLine[]
}

// function to determine margin depending of linelength
export function getMargin(lineLength: number): number{
return Math.min(maxMarginBetweenCards, (100*screenRatio-(cardInLineWidth*lineLength+2))/(lineLength+1)) //considering that there is always two virtual cards, one on each card
}

function getNewLocationX(x: number, decreeMove: MoveCard | undefined) : number{
  if (!decreeMove || decreeMove.on) return x
  if (x < decreeMove.from.x && x >= decreeMove.to) return x + 0.75
  else if (x > decreeMove.from.x && x < decreeMove.to) return x - 0.75
  // some adjustements to make it more comfortable
  else if (x>decreeMove.from.x && x>decreeMove.to) return (decreeMove.from.x<decreeMove.to)? x+0.25 : x-0.25
  else if (x<decreeMove.from.x && x<decreeMove.to) return (decreeMove.to<decreeMove.from.x)? x-0.25 : x+0.25
  else return x
}

export default function LineDisplay({ cards }: Props) {
  // console.log('Line display', cards)
  const possiblePlaces = usePossiblePlaces()
  const legalMoves = useLegalMoves()
  const game = useGame<Game>()
  const play = usePlay()
  
  const legalMovesWithTarget = legalMoves.filter(isMoveWithTarget)

  const {sourceClientOffset, card} = useEfficientDragLayer(monitor => ({
    sourceClientOffset: monitor.getDifferenceFromInitialOffset(),
    card: monitor.getItem() as CardInLine | null
  }))

  if (card && sourceClientOffset) {
    var decreeMove = getDecreeMove(cards, legalMoves, card, sourceClientOffset)
  }
  //assuming that all legalmoves are same type, if they are movecard, get all card except decree (who is on the pointer)
  const draggableCards = (legalMoves[0]?.type != MoveType.moveCard)? [] : getVisibleLine(cards).filter(card=>card.location.x!=game?.pointer) ;

  const possibleTargetsMap : Map<LineLocation, Move> = new Map(legalMovesWithTarget.map((move) => [move.target, move]))


  const lineLength = getLineLength(cards)
  // const median = getMedian(cards)
  const median = (lineLength)/2

  const margin = getMargin(lineLength) //considering that there is always two virtual cards, one on each card

  return (
    <div css={sizeCss}>
      {cards.map((card) => (
        <CardDisplay
          key={
            card.color.toString() +
            '_' +
            card.location.x +
            '_' +
            card.location.z
          }
          card={card}
          covered = {getCardAtLocation(cards, {type:LocationType.line, x:card.location.x, z:card.location.z+1})!==undefined}
          css={[
            cardCss(
              getNewLocationX(card.location.x, decreeMove), 
              card.location.z, median, margin
            ),
            game?.phase === Phase.Resolution &&
              card === getCardVisibleOnPointer(cards, game?.pointer) &&
              highLightCss(card.color),
            decreeMove?.on && decreeMove.to==card.location.x && card === getCardVisibleOnPointer(cards, decreeMove.to) &&
            overCss()
          ]}
          canDrag={draggableCards.includes(card)}
          highlighted={possibleTargetsMap.has(card.location)}
          onClick={() =>  {
            if (possibleTargetsMap.has(card.location)) play(possibleTargetsMap.get(card.location))
          }}

        />
      ))}
      {possiblePlaces.map((lineLocation) => (
        <CardDropArea
          key={'possiblePlace_' + lineLocation.x}
          location={lineLocation}
          css={cardCss(lineLocation.x, Math.max(lineLocation.z - 1, 1), median, margin)} //z-1 because you display card drop on the last visible card
        />
      ))}

      <div css={lanceCss} />
      {draggableCards.length>1 && <MoveCardDropArea cards={cards} legalMoves={legalMoves}/>} 
    </div>
  )
}

const cardCss = (x: number, z: number, median: number, margin : number) => css`
  position: absolute;
  width: ${cardWidth}em;
  height: ${cardHeight}em;
  bottom: ${(70 - cardHeight / 2) / cardInlineRatio - (z - 1) * vOverlap * cardHeight}em;
  left: ${getLeft(x, z, median, margin)}em;
  z-index: ${x + z - 1};
  transition: left 0.4s ease-in-out, bottom 0.2s ease-in-out;
`

function getLeft(x: number, z: number, median: number, margin : number){
  const offsetDueToZ = (z - 1) * hOverlap * cardWidth
  const fromCenter = screenRatio * 50 / cardInlineRatio
  const offsetDueToMedian = - (median * cardWidth + Math.floor(median) * margin)
  const offsetDueToCardPlace = (x-1) * (cardWidth+margin)

  return fromCenter + offsetDueToCardPlace + offsetDueToMedian + offsetDueToZ;
}

const lanceCss = css`
  position: absolute;
  background-image: url(${Images['lance']});
  background-size: contain;
  width: ${cardWidth * 2}em;
  height: ${(cardWidth * 2) * lanceRatio}em;
  bottom: ${(47 - ((cardWidth * 2) * lanceRatio) / 2) / cardInlineRatio }em;
  left: ${(screenRatio * 50)/ cardInlineRatio - (cardWidth * 2) / 2  }em;
`

const highLightCss = (color: number) => css`
  box-shadow: 0 0 3em ${colors[color]}, 0 0 5em white;
  border-radius: 1.5em;
`

const sizeCss = css`
    font-size: ${cardInlineRatio}em;
`

const overCss = () => {
  console.log("ok")
  return css`

  ::after  {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 1.5em;
    background-color: rgba(100, 255, 100, 0.5);
}
`
}
