/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import Images from './images/Images'
import { HTMLAttributes } from 'react'
import { cardHeight, cardWidth } from './styles'
import Coin from './Coin'

type Props = {
  money: number
} & HTMLAttributes<HTMLDivElement>

export default function MoneyCounter({ money, ...props }: Props) {
  return (
    <div css={blockCss} {...props}>
      <Coin css={moneyCss} />
      <div css={textCss}>{money}</div>
    </div>
  )
}

// export default function CardDisplay({card, ...props}: Props) {
//     let cardType = card.type ? card.type.toString : 'back'
//     let cardName = card.color.toString + '_' + cardType

//     return (
//       <div css={[style]}>{cardName}</div>
//     )
//   }

const moneyCss = css`
width: ${cardWidth}em;
height: ${cardWidth}em;
text-align: center;

  }
`

const textCss = css`
position: absolute;
  font-weight: bold;
  color: white;
  text-shadow: 0 0 0.2em black, 0 0 0.2em black, 0 0 0.2em black;
    left: 0.1em;
    top: 0.2em;
    display: inline-block;
    font-size: 8em;
`
const blockCss = css`
  position: absolute;
  align-items: center;
`
