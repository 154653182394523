/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import Images from './images/Images'
import { HTMLAttributes } from 'react'
import { coinSize } from './styles'

type Props = HTMLAttributes<HTMLDivElement>

export default function Coin({ ...props }: Props) {
  return <div css={[moneyCss]} {...props} />
}

const moneyCss = css`
display: inline-block;
width: ${coinSize}em;
height: ${coinSize}em;
background-image: url(${Images['money']});
background-size: contain;

  }
`
