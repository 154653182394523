import {isEnumValue} from '@gamepark/rules-api'

enum CardType {
    Ambush = 1,
    Archer = 2,
    Assassination = 3,
    Conspiracy = 4,
    Decree = 5,
    Heir = 6,
    Lord = 7,
    Shapeshifter = 8,
    Soldier = 9,
    Spy = 10     
}

export default CardType

export const cardTypes = Object.values(CardType).filter(isEnumValue)