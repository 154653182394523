/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react'

import Card, {
  CardInLine,
  isCardInLine,
  isHandLocation,
} from '@gamepark/oriflamme/Card'
import Color from '@gamepark/oriflamme/Color'
import Player from '@gamepark/oriflamme/Player'
import { usePlayerId } from '@gamepark/react-client'
import LineDisplay from './LineDisplay'
import OtherPlayerHand from './OtherPlayerHand'
import PlayerHand from './PlayerHand'

type Props = {
  cards: Card[]
  players: Player[]
}

export default function CardsDisplay({ cards, players }: Props) {
  const pId = usePlayerId()

  const { line, trash, playerCards } = filterCards(cards, players)

  let hands = []
  let index = 0

  for (const player of players) {
    const color = player.id
    if (color == pId) {
      hands.push(
        <PlayerHand key={'hand_' + color} cards={playerCards[color]!} player={color} />,
      )
    } else {
      hands.push(
        <OtherPlayerHand
          key={'hand_' + color}
          position={index++}
          cards={playerCards[color]!}
        />,
      )
    }
  }

  return (
    <>
      {hands}
      <LineDisplay cards={line} />
    </>
  )
}

function filterCards(
  cards: Card[],
  players: Player[],
): {
  line: CardInLine[]
  trash: Card[]
  playerCards: Partial<Record<Color, Card[]>>
} {
  let line: CardInLine[] = []
  let trash: Card[] = []
  let cardsByColor = {}
  for (const card of cards) {
    if (isCardInLine(card)) {
      line.push(card)
    } else if (isHandLocation(card.location)) {
      if (card.color in cardsByColor) {
        cardsByColor[card.color].push(card)
      } else {
        cardsByColor[card.color] = []
        cardsByColor[card.color].push(card)
      }
    } else {
      trash.push(card)
    }
  }
  return { line: line, trash: trash, playerCards: cardsByColor }
}
