import Color from "@gamepark/oriflamme/Color"
import Game from "@gamepark/oriflamme/Game"
import Move, { MoveType } from "@gamepark/oriflamme/moves/Move"
import Phase from "@gamepark/oriflamme/Phase"
import { Animations } from "@gamepark/react-client"
import AnimationContext from "@gamepark/react-client/dist/animations/AnimationContext"

export default class OriflammeAnimations extends Animations<Game, Move, Color> {//changed gameview to game and moveview to move
    override getPreDuration(move: Move, {playerId, state}: AnimationContext<Game, Move, Color>): number {
      switch (move.type) {
        case MoveType.placeCard:
          return 0
        case MoveType.revealCard:
          return 0
        default:
            return 1
      }
    }
  
    override getPostDuration(move: Move, {playerId, state}: AnimationContext<Game, Move, Color>): number {
      switch (move.type) {
        
        default:
          return 1
      }
    }
  }