import Card, { CardInLine } from '../../Card'
import CardType from '../../CardType'
import Color from '../../Color'
import {
  getBeforeAndAfterCards,
  getCardAtLocation,
  getCardVisibleOnPointer,
} from '../../Line'
import Move, { MoveType } from '../../moves/Move'
import OriflammeCardsRules from '../OriflammeCardsRules'
import ArcherRules from './ArcherRules'
import HeirRules from './HeirRules'
import LordRules from './LordRules'
import SoldierRules from './SoldierRules'
import SpyRules from './SpyRules'

/**
 * This class implements the rules of the board game.
 * It must follow Game Park "Rules" API so that the Game Park server can enforce the rules.
 */
export default class ShapeshifterRules extends OriflammeCardsRules  {
  delegate(): OriflammeCardsRules | undefined {
    const card = getCardVisibleOnPointer(this.state.cards, this.state.pointer)
    switch (
      card?.choosenType
    ) {
      case CardType.Archer:
        return new ArcherRules(this.state)
      case CardType.Heir:
        return new HeirRules(this.state)
      case CardType.Lord:
        return new LordRules(this.state)
      case CardType.Soldier:
        return new SoldierRules(this.state)
      case CardType.Spy:
        return new SpyRules(this.state)
      default:
        return
    }
  }

  getTargets(): CardInLine[] {
    return getBeforeAndAfterCards(this.state.cards, this.state.pointer)
  }

  /**
   * Return the exhaustive list of moves that can be played.
   * This is used for 2 features:
   * - security (preventing unauthorized moves from being played);
   * - "Dummy players": when a player leaves a game, it is replaced by a "Dummy" that plays random moves, allowing the other players to finish the game.
   * If the game allows a very large (or infinite) number of moves, instead of implementing this method, you can implement instead:
   * - isLegal(move: Move):boolean, for security; and
   * - A class that implements "Dummy" to provide a custom Dummy player.
   */
  getLegalMoves(playerId: Color): Move[] {
    //if it's not player in arg turn : return []
    if (this.getActivePlayer() !== playerId) return []

    const card = getCardVisibleOnPointer(this.state.cards, this.state.pointer)

    if (card?.choosenType) return super.getLegalMoves(playerId)

    const targets = this.getTargets()

    return targets.filter(card=>card.type!==undefined && !card.flipped && card.type!=CardType.Shapeshifter).map(
      card=>{ return {type: MoveType.copyType, target: card.location}}
    )
  }

  /**
   * This is the one and only play where you will update the game's state, depending on the move that has been played.
   *
   * @param move The move that should be applied to current state.
   */
  play(move: Move): Move[] {
    switch (move.type) {
      case MoveType.copyType:
        console.log('copyType', move)
        const card = getCardVisibleOnPointer(this.state.cards, this.state.pointer)
        if (card!== undefined) card.choosenType = getCardAtLocation(this.state.cards, move.target)!.type
    }

    return []
  }
}
