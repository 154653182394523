import Move, { MoveType } from "@gamepark/oriflamme/moves/Move"
import OriflammeRules from "@gamepark/oriflamme/OriflammeRules"

export default class OriflammeView extends OriflammeRules {
  
    getAutomaticMoves(): Move[] {
      return this.keepPredictableMoves(super.getAutomaticMoves())
    }
  
    play(move: Move): Move[] {
      return this.keepPredictableMoves(super.play(move))
    }
  
    keepPredictableMoves(moves: Move[]): (Move)[] {
      const firstUnpredictableMoveIndex = moves.findIndex(move => !this.isPredictableMove(move))
      return (firstUnpredictableMoveIndex !== -1 ? moves.slice(0, firstUnpredictableMoveIndex) : moves) as (Move)[]
    }

    isPredictableMove(move: Move): boolean {
      return move.type !== MoveType.showCard
    }

}