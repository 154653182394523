/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react'
import Game from '@gamepark/oriflamme/Game'
import { usePlayer, usePlayerId } from '@gamepark/react-client'
import { Letterbox } from '@gamepark/react-components'
// import Color from '@gamepark/oriflamme/Color'
// import CardType from '@gamepark/oriflamme/CardType'
import CardsDisplay from './CardsDisplay'
// import CoinsDisplay from './CoinsDisplay'
import PlayerPanel from './PlayerPanel'

type Props = {
  game: Game
}

export default function GameDisplay({ game }: Props) {
  const pId = usePlayerId()
  let index = 0
  return (
    <Letterbox id='letterbox' css={letterBoxStyle} top={0}>
      <CardsDisplay cards={game.cards} players={game.players} />
      {/* useless now <CoinsDisplay players={game.players} cards={game.cards} /> */}
      {game.players.map(
        (player) =>
          player.id != pId && (
            <PlayerPanel
              key={player.id}
              player={player}
              cards={game.cards}
              position={index++}
              gameOver={false}
            />
          ),
      )}
      {game.players.map(
        (player) =>
          player.id == pId && (
            <PlayerPanel
              key={player.id}
              player={player}
              cards={game.cards}
              position={4}
              gameOver={false}
            />
          ),
      )}
    </Letterbox>
  )
}

const fadeIn = keyframes`
  from, 50% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const letterBoxStyle = css`
  animation: ${fadeIn} 3s ease-in forwards;
`
