/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react'

import Card, { isHandLocation } from '@gamepark/oriflamme/Card'
import { usePlayerId } from '@gamepark/react-client'
import { Hand } from '@gamepark/react-components'
import { HTMLAttributes } from 'react'
import CardDisplay from './CardDisplay'
import {
  cardHeight,
  cardWidth,
  opponentSize,
  playerPanelWidth,
  playerPanelX,
  playerPanelY,
  screenRatio,
} from './styles'

type Props = {
  cards: Card[]
  position: number
} & HTMLAttributes<HTMLDivElement>

export default function OtherPlayerHand({ cards, position }: Props) {
  let index = 0
  return (
    <Hand css={handCss(position)} sizeRatio={cardWidth / cardHeight}>
      {cards.map((card) => (
        <CardDisplay key={card.color.toString() + '_' + index++} card={card} covered={false}/>
      ))}
    </Hand>
  )
}

const handCss = (position: number) => css`
  font-size: ${opponentSize}em;
  position: absolute;
  width: ${cardWidth}em;
  height: ${cardHeight}em;
  top: ${(playerPanelY(position) + 8.5) / opponentSize}em;
  left: ${(playerPanelX(position) + playerPanelWidth / 2 + 2) / opponentSize}em;
`
