import { Rules } from '@gamepark/rules-api'
import Card, { CardInLine } from '../../Card'
import Color from '../../Color'
import Game from '../../Game'
import { getCardVisibleOnPointer, getVisibleLine } from '../../Line'
import Move, { KillCard, MoveType } from '../../moves/Move'
import OriflammeCardsRules from '../OriflammeCardsRules'

/**
 * This class implements the rules of the board game.
 * It must follow Game Park "Rules" API so that the Game Park server can enforce the rules.
 */
export default class AmbushRules extends OriflammeCardsRules {
  getTargets(): CardInLine[] {
    const line = getVisibleLine(this.state.cards)
    return [line[0], line[line.length-1]]
  }

  /**
   * Return the exhaustive list of moves that can be played.
   * This is used for 2 features:
   * - security (preventing unauthorized moves from being played);
   * - "Dummy players": when a player leaves a game, it is replaced by a "Dummy" that plays random moves, allowing the other players to finish the game.
   * If the game allows a very large (or infinite) number of moves, instead of implementing this method, you can implement instead:
   * - isLegal(move: Move):boolean, for security; and
   * - A class that implements "Dummy" to provide a custom Dummy player.
   */
  getLegalMoves(playerId: Color): KillCard[] {
    //if it's not player in arg turn : return []
    if (this.getActivePlayer() !== playerId) return []

    const targets = this.getTargets()

    return targets.map((target) => {
      return { type: MoveType.killCard, target: target.location }
    })
  }

  /**
   * Here you can return the moves that should be automatically played when the game is in a specific state.
   * Here is an example from monopoly: you roll a dice, then move you pawn accordingly.
   * A first solution would be to do both state updates at once, in a "complex move" (RollDiceAndMovePawn).
   * However, this first solution won't allow you to animate step by step what happened: the roll, then the pawn movement.
   * "getAutomaticMoves" is the solution to trigger multiple moves in a single action, and still allow for step by step animations.
   * => in that case, "RollDice" could set "pawnMovement = x" somewhere in the game state. Then getAutomaticMove will return "MovePawn" when
   * "pawnMovement" is defined in the state.
   * Of course, you must return nothing once all the consequences triggered by a decision are completed.
   * VERY IMPORTANT: you should never change the game state in here. Indeed, getAutomaticMove will never be called in replays, for example.
   *
   * @return The next automatic consequence that should be played in current game state.
   */
  getAutomaticMoves(): Move[] {
    return super.getAutomaticMoves()
  }
}
