/** @jsxImportSource @emotion/react */
import {getPlayerName} from '@gamepark/oriflamme/OriflammeOptions'
import {usePlay, usePlayerId} from '@gamepark/react-client'
import {Trans, useTranslation} from 'react-i18next'
import Game from '@gamepark/oriflamme/Game'
import Phase from '@gamepark/oriflamme/Phase'
import useLegalMoves from './useLegalMoves'
import PlacementRules from '@gamepark/oriflamme/phases/PlacementRules'
import {getCardVisibleOnPointer} from '@gamepark/oriflamme/Line'
import CardType from '@gamepark/oriflamme/CardType'

type Props = {
  loading: boolean
  game?: Game
}

export default function HeaderText({ loading, game }: Props) {
  const { t } = useTranslation()
  const playerId = usePlayerId()
  const legalMoves = useLegalMoves()
  const play = usePlay()

  if (loading || !game) return <>{t('Game loading...')}</>
  if (game.phase == Phase.Placement) {
    const activePlayer = new PlacementRules(game).getActivePlayer()
    return activePlayer == playerId ?
      <>{t('You must play a card')}</>
      :
      <>{t('{player} must play a card', {player: getPlayerName(activePlayer, t)})}</>
  } else {
    //We are in Phase.revelation
    const activeCard = getCardVisibleOnPointer(game.cards, game.pointer)
    if (activeCard){
      if (!activeCard.flipped){
        return (<Trans
            defaults= {(activeCard.color == playerId) ? myHelpTexts[activeCard.choosenType || activeCard.type!] : helpTexts[activeCard.choosenType || activeCard.type!]}
            values={{ player: getPlayerName(activeCard.color, t) }}
          />)}
      else {
        if (activeCard.color == playerId)
        return (
          <Trans
            defaults="You can <0>reveal</0> your card or <1>pass</1>"
            components={[
              <button onClick={() => play(legalMoves[0])} />,
              <button onClick={() => play(legalMoves[1])} />,
            ]}
          />
        )
        else return (<>{t('{player} can reveal their card or pass', { player: getPlayerName(activeCard.color, t) })}</>)
      }
    }
  }
  return <>{t('Game is over')}</>
}

const myHelpTexts : Record<CardType,string> = {
  [CardType.Ambush]: 'You win one point',
  [CardType.Archer]: 'You must eliminate the first or the last card of the queue',
  [CardType.Assassination]: 'You must eliminate any card in the queue',
  [CardType.Conspiracy]: 'You gain double points',
  [CardType.Decree]: 'You must move a card anywhere in the queue',
  [CardType.Heir]: 'You gain 2 points if you are the only card of its type in the queue',
  [CardType.Lord]: 'You gain 1 point for you and all ajacents cards of your family',
  [CardType.Shapeshifter]: 'You must copy the ability of an adjacent revealed card',
  [CardType.Soldier]: 'You must eliminate an adjacent card',
  [CardType.Spy]: 'You must steal one point from a player who as a card adjacent to the active card',
}

const helpTexts : Record<CardType,string> = {
  [CardType.Ambush]: '{player} wins one point',
  [CardType.Archer]: '{player} must eliminate the first or the last card of the queue',
  [CardType.Assassination]: 'You must eliminate any card in the queue',
  [CardType.Conspiracy]: '{player} gains double points',
  [CardType.Decree]: '{player} must move a card anywhere in the queue',
  [CardType.Heir]: '{player} gains 2 points if this is the only card of its type in the queue',
  [CardType.Lord]: '{player} gains 1 point for you and all ajacents cards of their family',
  [CardType.Shapeshifter]: '{player} must copy the ability of an adjacent revealed card',
  [CardType.Soldier]: '{player} must eliminate an adjacent card',
  [CardType.Spy]: '{player} must steal one point from a player who as a card adjacent to the active card',
}